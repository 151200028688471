import React from 'react';
import { Container, UserInfo } from './styles';
import { MdEdit } from 'react-icons/md'
import api from '../../services/api';
import Swal from 'sweetalert2'
import { format } from 'date-fns'

const UserList = ({ usersList, usersListModified }) => {
  const messageDelete = async (userId) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Você tem certeza?',
      text: "Se você excluir este usuário, irá perder permanentemente os links cadastrados por ele!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, delete!',
      cancelButtonText: 'Não, cancele!',
      confirmButtonColor: '#72ce17',
      cancelButtonColor: '#d33',
    })
    if (isConfirmed) {
      await handleDelete(userId);

    }
    else {
      Swal.fire(
        'Cancelado',
        'Ufa! O usuário e os links estão salvos!',
        'error'
      )
    }
  }

  const handleDelete = async id => {
    try {
      let token = localStorage.getItem('token')
      await api({
        headers: {Authorization: `Bearer ${token}`},
        method: 'DELETE',
        withCredentials: false,
        withXSRFToken: true,
        url: `users/${id}`
      });

      usersListModified(
        usersList.filter(user => user.id !== id)
      )

      Swal.fire(
        'Deletado!',
        'O usuário foi deletado!',
        'success'
      )
    } catch (error) {
      if (error?.response?.status === 422 && error?.response?.data?.message !== undefined) {
        Swal.fire({
          title: 'Ocorreu um erro ao remover o usuário',
          text: error?.response?.data?.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          confirmButtonColor: '#72ce17',
        })
      }
    }
  }

  return (
    <Container>
      <ul>
      {usersList.map(user => {
        let dataFormatada = ''

        if (user.created_at !== undefined) {
          dataFormatada = format(new Date(user.created_at), 'dd/MM/yyyy H:m:s')
        }
        return (
          <li key={user.id}>
            <UserInfo>
              <div>
                <strong>{user.name.length < 50 ? user.name : user.name.substring(0, 50) + '...'}</strong>
                <span>
                  {`${user.email} | ${dataFormatada} |`}
                  {<button onClick={() => messageDelete(user.id)}>Excluir</button>}
                </span>
              </div>
            </UserInfo>
            <div>
              {(
                <a href={`/users/edit/${user.id}`} title='Editar cadastro do usuário'>
                  <MdEdit style={{ marginRight: 8 }} size={24} color='#222'/>
                </a>
              )}
            </div>
          </li>
        )
      })}
      </ul>
    </Container>
  );
}

export default UserList;