import React, { Component } from 'react';
import Formulario from './components/Formulario';
import { Content } from './styles';

class UsersCreate extends Component {
  render() {
    return (
      <Content>
        <h3>Cadastro de usuário</h3>
        <Formulario />
      </Content>
    )
  }
}

export default UsersCreate