import { useEffect, useState } from 'react'
import api from './services/api';
import { Content, EmptyList } from './styles';
import Formulario from './components/Formulario';
import { useParams } from "react-router-dom";

function UsersEdit() {
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(true)

  const { idUser } = useParams();
  let token = localStorage.getItem('token')

  useEffect(() => {(
    async () => {
      try {
        const { data } = await api({
          headers: {Authorization: `Bearer ${token}`},
          method: 'GET',
          withCredentials: false,
          withXSRFToken: true,
          url: 'users/' + idUser
        });

        if (data?.user !== undefined) {
          setUserData(data?.user)
        }
      } catch (error) {}
      setLoading(false)
    })();
  }, [idUser, token, loading]);

  return (
    <Content>
      <h3>Editar cadastro de usuário</h3>
      {userData.id !== undefined ? <Formulario user={userData}/> :
      <EmptyList>{loading ? 'Carregando dados do usuário...' : 'Nenhum usuário encontrado.'}</EmptyList>}
    </Content>
  )
}

export default UsersEdit