import styled, {css} from 'styled-components';

const DragActive = css`
border-color: #3da019;

`

const DragReject = css`
    border-color: #e56878
`

export const DropContainer = styled.div.attrs({
    className: "dropzone"
})`
    border: 1px solid #223b69;
    border-radius: 4px;
    cursor: pointer;
    transition: height 0.2s ease;

    ${props => props.isdragactive && DragActive };
    ${props => props.isdragreject && DragReject };
`;

const messageColors ={
    default:'#999',
    error: '#e57878',
    success:'#3da019'
};

export const UploadMessage = styled.p`

display:flex;
color:${props=>messageColors[props.type ||'default']};
justify-content:center;
align-items: center;
padding: 15px 0;
`
