import { createGlobalStyle } from 'styled-components'
import 'react-circular-progressbar/dist/styles.css'

export default createGlobalStyle`
    * {
        margin:0;
        padding:0;
        outline:0;
        box-sizing: border-box;
    }
    body {
        font-family: 'Montserrat', sans-serif;
        font-size:18px;
        font-color: #4B4B4B;

        text-rendering: optimizeLegibility;
        -webkit-font-smoothing:antialiased;
    }

    h1 {
        color: #4B4B4B;
    }

    h3 {
        color: #4B4B4B;
        margin-bottom: 10px;
        margin-top: 0px;
    }

    html, body, #root{
        height: 100%;
    }
    body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown), html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
        height: 100% !important;
    }

    .login button {
        background: #DB0A70;
        padding: 18px 35px;
        font-size: 13px;
        line-height: 16px;
        color: #ffffff;
        font-weight: bold;
        letter-spacing: 1px;
        border-radius: 5px;
        display: block;
        border: none;
        cursor: pointer;
    }

    .login button:hover {
        background: #BC075F;
    }

    .user {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        max-height: 400px;
    }

    .user button {
        background: #DB0A70;
        padding: 18px 35px;
        font-size: 13px;
        line-height: 16px;
        color: #ffffff;
        font-weight: bold;
        letter-spacing: 1px;
        border-radius: 5px;
        display: block;
        border: none;
        cursor: pointer;
        width: 150px;

    }
    .user button:hover {
        background: #BC075F;
    }

    .linkTop {
        padding-top: 10px;
        padding-bottom: 5px;
        text-align: right;
    }
    .linkTop a {
        font-size: 13px;
        color: #DB0A70;
    }
    .linkTop a:hover {
        font-size: 13px;
        color: #BC075F;
    }

    .divButton {
        display: flex;
        justify-content: right;
    }

`;
