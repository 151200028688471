import React, { Component } from 'react';
import { filesize } from 'filesize';
import api from './services/api';
import { Content, EmptyList } from './styles'
import Upload from './components/Upload'
import FileList from './components/FileList'
import { uniqueId } from 'lodash';

class Uploads extends Component {
  state = {
    uploadedFiles: [],
  };

  token = localStorage.getItem('token')
  loading = true
  baseUrl = ''

  async componentDidMount() {
    try {
      const { data } = await api({
        headers: {Authorization: `Bearer ${this.token}`},
        method: 'GET',
        withCredentials: false,
        withXSRFToken: true,
        url: 'links'
      });
      if (data?.links?.length) {
        this.baseUrl = data.base_url

        this.setState({
          uploadedFiles: data?.links?.map(file => ({
            id: file.id,
            name: file.name,
            readableSize: filesize(file.size),
            preview: file.url,
            date: file.created_at,
            uploaded: true,
            url: file.url,
            user: file.user.email
          }))
        })
      }
    } catch (error) {}
    this.loading = false
  }

  handleUpload = files => {
    const uploadedFiles = files.map(file => ({
      file,
      id: 'temp-' + uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      date: file.created_at,
      user: 'carregando aguarde..',
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }))

    this.setState({
      uploadedFiles: uploadedFiles.concat(this.state.uploadedFiles)
    });
    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile;
      })
    });
  };

  processUpload = (uploadedFile) => {
    const data = new FormData();
    data.append('file', uploadedFile.file, uploadedFile.name);

    try {

      api.post('links', data, {
        headers: {Authorization: `Bearer ${this.token}`},
        withCredentials: false,
        withXSRFToken: true,
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress,
          })
        }
      }).then(response => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.link.id,
          url: `${response.data.link.url}`,
          user: response.data.link.user.email,
          date: response.data.link.created_at,
        });
      }).catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true
        });
      })
    } catch (error) {}
  };

  handleDelete = async id => {
    await api({
      headers: {Authorization: `Bearer ${this.token}`},
      method: 'DELETE',
      withCredentials: false,
      withXSRFToken: true,
      url: `links/${id}`
    });

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id),
    })
  }

  componentWillUnmount() {
    this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }

  render() {
    const { uploadedFiles } = this.state;
    return (
      <Content>
        <h3>Gerenciamento de links</h3>
        <hr/>
        <div className='linkTop'>
          <a href='/links/export'>Exportar lista</a>
        </div>
        <Upload onUpload={this.handleUpload} />
        {!!uploadedFiles.length ? (
          <FileList key={'file-1'} files={uploadedFiles} baseUrl={this.baseUrl} onDelete={this.handleDelete} />
        ) : <EmptyList>{this.loading ? 'Carregando lista de links...' : 'Nenhum arquivo cadastrado para ser exibido.'}</EmptyList>}
      </Content>
    )
  }
}

export default Uploads;
