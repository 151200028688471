import React, { useState } from 'react'
import api from './services/api'
import { makeStyles, TextField } from '@material-ui/core';
import { Form } from './styles'
import useAuth from './hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch'
    },
  },
}));

export default function Login(props) {
  const [loginUsername, setLoginUsername] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const {setToken} = useAuth()

  const login = async (e) => {
    e.preventDefault()
    try {
      const { data } = await api({
        method: 'POST',
        data: {
          email: loginUsername,
          password: loginPassword,
        },
        withCredentials: false,
        withXSRFToken: true,
        url: '/login',
      })
      if (data?.access_token) {
        localStorage.setItem("token", data.access_token)
        setToken(data.access_token)
      }
    } catch (error) {
    }
  };

  const classes = useStyles();
  return (
    <div>
      <div className='login'>
        <h1>Faça seu login</h1><br />
        <Form onSubmit={login} className={classes.root} autoComplete="off">
          <TextField label="Email de login" variant="outlined" placeholder="seu email de login"
            onChange={(e) => setLoginUsername(e.target.value)} required={true} /><br />
          <TextField label="Senha" variant="outlined" placeholder="sua senha do sistema"
            onChange={(e) => setLoginPassword(e.target.value)} required={true} type={'password'} /><br />
          <button variant="outlined" type="submit">ENTRAR</button>
        </Form>
      </div>
    </div>
  )
}