import React, {useState} from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import { Form } from './styles';
import api from '../../services/api';
import { EmptyList } from '../../styles';
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '400px'
    },
  },
}));

const Formulario = (props) => {
  const [message, setMessage] = useState('');
  const [id, setId] = useState(props.user?.id ? props.user.id : '');
  const [name, setName] = useState(props.user?.name ? props.user.name : '');
  const [email, setEmail] = useState(props.user?.email ? props.user.email : '');
  const [password, setPassword] = useState('');

  const classes = useStyles();
  const navigate = useNavigate();

  const messageRedirect = (message, url) => {
    setMessage(message)
    setTimeout(() => {
      navigate(url)
    }, 2500)
  }

  const messageError = async (message) => {
    await Swal.fire({
      title: 'Ocorreu um erro ao salvar o usuário',
      text: message,
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#72ce17',
    })
  }

  const save = async (event) => {
    event.preventDefault();

    let token = localStorage.getItem('token')

    try {
      if (id !== '') {
        const { data } = await api({
          headers: {Authorization: `Bearer ${token}`},
          method: 'PUT',
          withCredentials: false,
          withXSRFToken: true,
          url: 'users/' + id,
          data: {id, name, email, password}
        });
      } else {
        const { data } = await api({
          headers: {Authorization: `Bearer ${token}`},
          method: 'POST',
          withCredentials: false,
          withXSRFToken: true,
          url: 'users',
          data: {name, email, password}
        });
      }
      setId('')
      setName('')
      setEmail('')
      setPassword('')

      messageRedirect('Usuário salvo com sucesso!', '/users')

    } catch (error) {
      if (error?.response?.status === 422 && error?.response?.data?.message !== undefined) {
        messageError(error?.response?.data?.message)
      }
    }
  }

  return (
    <div className='user'>
      {message === '' ?
        <Form onSubmit={save} className={classes.root}  autoComplete="off">
          <input type={'hidden'} value={id ? id : ''} onChange={id => setId(id)}/>
          <TextField label="Nome" variant="outlined" placeholder="seu nome"
            required={true} autoComplete='new-password' value={name} onChange={e => setName(e.target.value)}/><br />
          <TextField label="Email de login" variant="outlined" placeholder="seu email de login"
            required={true} autoComplete='new-password' value={email} onChange={e => setEmail(e.target.value)}/><br />
          <TextField label="Senha" variant="outlined" placeholder="sua senha do sistema"
            required={(id === '')} type={'password'} autoComplete='new-password' onChange={e => setPassword(e.target.value)}/><br />
          <div className={'divButton'}>
          <button variant="outlined" type="submit">Salvar</button>
          </div>
        </Form>
        : <EmptyList>{message}</EmptyList>}
    </div>
  );
}

export default Formulario;