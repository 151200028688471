import React from 'react'
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import Uploads from '../Uploads'
import Logout from '../Logout'
import Users from '../Users';
import UsersCreate from '../UsersCreate';
import UsersEdit from '../UsersEdit';
import LinksExport from '../LinksExport';

export default function Routes(props) {
  return (
    <ReactRoutes>
      <Route path="/">
        <Route path="/" element={<Uploads/>} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/create" element={<UsersCreate />} />
        <Route path="/users/edit/:idUser" element={<UsersEdit/>} />
        <Route path="/links/export" element={<LinksExport/>} />
        <Route path="/logout" element={<Logout setLogin={props.setLogin}/>} />
      </Route>
    </ReactRoutes>
  )
}