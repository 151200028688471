import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction:row;
    align-items: stretch;
    justify-content: center;
    padding-bottom: 35px;
    margin-top: 10px;
`;

export const Content = styled.div`
    width:100%;
    max-width:800px;
    border-radius:4px;
    padding: 20px;
    max-height: 600px;
    box-shadow: 0px 1px 9px 1px rgba(34,59,105,1);
    -webkit-box-shadow: 0px 1px 9px 1px rgba(34,59,105,1);
    -moz-box-shadow: 0px 1px 9px 1px rgba(34,59,105,1);
`
export const Form = styled.form`
    margin-bottom: 500px !important
`