import React, { Component } from 'react';
import api from './services/api';
import { Content, EmptyList } from './styles';
import UserList from './components/UserList';

class Users extends Component {
  state = {
    usersList: [],
  };

  token = localStorage.getItem('token')
  loading = true

  async componentDidMount() {
    try {
      const { data } = await api({
        headers: {Authorization: `Bearer ${this.token}`},
        method: 'GET',
        withCredentials: false,
        withXSRFToken: true,
        url: 'users'
      });

      if (data?.users?.length) {
        this.baseUrl = data.base_url

        this.setState({
          usersList: data?.users
        })
      }
    } catch (error) {}
    this.loading = false
  }

  render() {
    const { usersList } = this.state;

    return (
      <Content>
        <h3>Gerenciamento de usuários</h3>
        <hr />
        <div className='linkTop'>
          <a href='/users/create'>Cadastrar Novo</a>
        </div>
        {!!usersList.length ? (
          <UserList usersList={usersList} usersListModified={valor => this.setState({usersList: valor})}></UserList>
        ) : <EmptyList>{this.loading ? 'Carregando lista de usuários...' : 'Nenhum usuário cadastrado para ser exibido.'}</EmptyList>}
      </Content>
    )
  }
}

export default Users