import React from 'react';
import { Container, FileInfo, Preview } from './styles';
import { CircularProgressbar } from 'react-circular-progressbar'
import { MdCheckCircle, MdError, MdFileCopy, MdLink } from 'react-icons/md'
import Swal from 'sweetalert2'
import { format } from 'date-fns'

const FileList = ({ files, onDelete, baseUrl }) => {
  const messageDelete = async (fileid) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Você tem certeza?',
      text: "Se você excluir, irá perder esse link permanentemente!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, delete!',
      cancelButtonText: 'Não, cancele!',
      confirmButtonColor: '#72ce17',
      cancelButtonColor: '#d33',
    })
    if (isConfirmed) {
      await onDelete(fileid);
      Swal.fire(
        'Deletado!',
        'Seu arquivo foi deletado!',
        'success'
      )
    }
    else {
      Swal.fire(
        'Cancelado',
        'Ufa! Seu arquivo está a salvo!',
        'error'
      )
    }
  }

  return (
    <Container>
      {files.map(uploadedFile => {
        var dataFormatada = ''
        const { name } = uploadedFile;

        if (uploadedFile.date !== undefined) {
          dataFormatada = format(new Date(uploadedFile.date), 'dd/MM/yyyy H:m:s')
        }

        return (
          <li key={uploadedFile.id}>
            <FileInfo>
              <Preview src={`${baseUrl}${uploadedFile.url}`}/>
              <div>
                <strong>{name.length < 20 ? name : name.substring(0, 20) + "..."}</strong>
                <span>
                  {uploadedFile.readableSize + ' | ' + uploadedFile.user}
                  {!!uploadedFile.url && ` | ${dataFormatada} |`}
                  {!!uploadedFile.url &&
                    (<button onClick={() => messageDelete(uploadedFile.id)}>Excluir</button>)}
                </span>
              </div>
            </FileInfo>
            <div>
              {!uploadedFile.uploaded && !uploadedFile.error && (
                <CircularProgressbar
                  styles={{
                    root: { width: 24 },
                    path: { stroke: '#223b69' }
                  }}
                  strokeWidth={10}
                  value={uploadedFile.progress}
                />
              )}
              {uploadedFile.url && (
                <a
                  href={`${baseUrl}${uploadedFile.url}`}
                  target='_blank'
                  rel='noopener noreferrer' title='Abrir link'>
                  <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
                </a>
              )}
              {uploadedFile.url && (
                  <MdFileCopy title='Copiar link' style={{ marginRight: 8, cursor: 'pointer' }} size={24} color="#222" onClick={() => {navigator.clipboard.writeText(`${baseUrl}${uploadedFile.url}`)}} />
              )}

              {uploadedFile.uploaded && <MdCheckCircle title='Status ok' size={24} color="#3da019" />}
              {uploadedFile.error && <MdError size={24} color="#e57878" />}
            </div>
          </li>
        )
      })}
    </Container>
  );
}

export default FileList;