import React, { Fragment } from 'react';
import { Topo1, Topo2, IconsFunctions } from "./styles";
import { ExitToApp, People, List } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";

export default function Header() {
  const {token} = useAuth(useAuth)

  return (
    <Fragment>
      <Topo1>
        <img alt="saggezza" src="/images/logo-saggezza.png" width="280px" />
      </Topo1>
      <Topo2>
        <h1>Gerador de Links</h1>
      </Topo2>
      {token && <IconsFunctions className='iconsFunction'>
        <NavLink title='Gerenciamento de links' to='/' className={({ isActive }) => (isActive ? 'active' : '')}><List></List></NavLink>
        <NavLink title='Gerenciamento de usuários' to='/users' className={({ isActive }) => (isActive ? 'active' : '')}><People></People></NavLink>
        <NavLink title='Logout' to='/logout' className={({ isActive }) => (isActive ? 'active' : '')}><ExitToApp></ExitToApp></NavLink>
      </IconsFunctions>}
    </Fragment>
  )
}