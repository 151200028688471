import { Fragment } from "react"
import Routes from './Routes/Routes'
import Header from './components/Header'
import GlobalStyle from './styles/global'
import { Container } from './styles'
import useAuth from "./hooks/useAuth";
import Login from "./Login"

function App() {
  const {token} = useAuth(useAuth)
  return (
    <Fragment>
        <Header/>
        <Container>
        {!token?<Login/>:<Routes/>}
        <GlobalStyle />
        </Container>
    </Fragment>
  );
}

export default App;
