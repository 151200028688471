import { setApp } from '../redux/actions';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {useCallback} from 'react';

const useAuth = ()=>{
    const dispatch = useDispatch();
    const {token} = useSelector(
    (state) => ({
      token: state.App.token,
    }),
    shallowEqual,
  );

  const setToken = useCallback(
    (token) => {
      dispatch(setApp({token: token}));
    },
    [dispatch],
  );

  return {token, setToken};
}

export default useAuth;