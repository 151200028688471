import React, { Component } from 'react'
import Dropzone from 'react-dropzone';
import { DropContainer, UploadMessage} from './styles';

export default class Upload extends Component {
    renderDragMessage = (isdragactive, isdragreject) =>{
        if (!isdragactive) {
            return <UploadMessage> Arraste seus arquivos aqui...</UploadMessage>
        }
        if(isdragreject){
            return<UploadMessage type="error">Arquivo não suportado</UploadMessage>
        }
        return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>
    }

    render() {
        const {onUpload} = this.props;
        return (
            <Dropzone accept={{
                'image/*': [],
                'application/pdf': [],
                'application/vnd.ms-excel': [],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                'application/msword': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                'application/zip': [],
                'text/plain': []
            }} onDropAccepted ={onUpload}>
                {({getRootProps, getInputProps, isdragactive, isdragreject})=>(
                    <DropContainer
                        {...getRootProps()}
                        isdragactive={isdragactive} isdragreject={isdragreject}>

                        <input {...getInputProps()}/>
                        {this.renderDragMessage(isdragactive,isdragreject)}
                    </DropContainer>
                )}
            </Dropzone>
        )
    }
};
