import styled from 'styled-components';

export const Topo1 = styled.div`
display: flex;
background: #dedede;
width: 100%;
flex-direction:row;
justify-content:center;
`;

export const Topo2 = styled.div`
display: flex;
align-items:center;
flex-direction:row;
justify-content: center;
background-color: #BC075F;
height: 50px;
margin:0px;
h1{
    font-size: 32px;
    color:#ffffff;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    margin:0px;
    text-transform: uppercase;
}
`;

export const IconsFunctions = styled.div`
display: flex;
align-items:center;
flex-direction:row;
justify-content: center;
background-color: #C2C2;
padding-top: 5px;
a {
    margin: 0 15px;
    color: #DB0A70;
}
a:hover {
    color: black
}
a.active {
    border-bottom: solid;
}
`