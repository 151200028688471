import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import useAuth from './hooks/useAuth';

const Logout = () => {
    const navigate = useNavigate();
    const {setToken} = useAuth()

    localStorage.removeItem("token")
    setToken('')
    useEffect(() => {(
        async () => {
            navigate('/', { replace: true });
        })();
    }, [navigate]);
};

export default Logout