import { useEffect, useState } from 'react';
import api from './services/api';
import { Content, EmptyList } from './styles';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

function LinksExport() {
  const [link, setLink] = useState('')
  let token = localStorage.getItem('token')

  useEffect(() => {(
    async () => {
      try {
        const { data } = await api({
          headers: {Authorization: `Bearer ${token}`},
          method: 'GET',
          withCredentials: false,
          withXSRFToken: true,
          url: 'links/export'
        });

        if (data?.link !== undefined) {
          setLink(data.link)
        }
      } catch (error) {}

    })();
  }, [token]);

  return (
    <Content>
      <h3>Exportar lista de links</h3>
      <hr/>
      <div className='linkTop'>
          <a href='/'>Voltar para a lista</a>
        </div>
      {link === '' ? <EmptyList>Aguarde carregando link...</EmptyList>:
      <EmptyList>
        <div><a href={link}><SystemUpdateAltIcon style={{transform: 'scale(1.8)', color: 'black', cursor: 'pointer'}}></SystemUpdateAltIcon></a></div>
        <div style={{marginTop: '20px'}}>Clique no ícone para baixar a planilha</div>
      </EmptyList>}
    </Content>
  )
}

export default LinksExport